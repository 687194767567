import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { toBRL, toBRLwithZero } from 'utils/currency';

import { pixDescription, cardDescription } from 'constants/price-descriptions';
import { getChannelConfig } from 'utils/session';

import withModal from 'hocs/withModal/withModal';
import InternationalTaxICMSModal from 'components/InternationalTaxICMSModal/InternationalTaxICMSModal';
import InternationalImportTaxModal from 'components/InternationalImportTaxModal/InternationalImportTaxModal';
import PromoCodeTag from 'components/HighlightPromoCode/PromoCodeTag/PromoCodeTag';
import PromoCodeModal from 'components/HighlightPromoCode/PromoCodeModal';
import isCouponJourneyEnable from 'utils/isCouponJourneyEnable';
import { getCouponInBasket } from 'utils/couponHelpers';
import './BasketPriceBox.scss';
import * as promocodeActions from 'reducers/promocode';
import { connect } from 'react-redux';
import { openCouponModalActions } from '../helpers';

const { bool, string, array, func, shape } = PropTypes;

const mapStateToProps = ({ basket }) => ({
  discounts: basket.basket.discounts,
  promocode: basket.basket.promocode,
});

const mapDispatchToProps = {
  promocodeEventVirtualPageView: promocodeActions.promocodeEventVirtualPageView,
  promocodeEventCustom: promocodeActions.promocodeEventCustom,
};

@connect(mapStateToProps, mapDispatchToProps)
class BasketPrices extends Component {
  static propTypes = {
    promocodeEventVirtualPageView: func.isRequired,
    discounts: shape({
      promocode: string,
    }),
    promocode: string,
    to: string.isRequired,
    cash: string,
    amountDescription: string,
    checkoutAmountDescription: string,
    shipment: string,
    amountWithoutDiscount: string,
    icms: string,
    importValue: string,
    totalDelivery: string,
    zipcode: string,
    products: array,
    unavailable: bool,
    finalTotal: bool.isRequired, // total = true, subtotal = false
  };

  constructor(props) {
    super(props);
    this.state = {
      isInternationalTaxICMSModalOpen: false,
      isInternationalImportTaxModalOpen: false,
      isCouponModalOpen: false,
    };
  }

  openCouponModal = () => {
    openCouponModalActions(this.props);
    this.setState({ isCouponModalOpen: true });
  };

  closeCouponModal = () => this.setState({ isCouponModalOpen: false });

  handleInternationalTaxICMSModalToggle = () => {
    this.setState({
      isInternationalTaxICMSModalOpen: !this.state.isInternationalTaxICMSModalOpen,
    });
  };

  handleInternationalImportTaxModalToggle = () => {
    this.setState({
      isInternationalImportTaxModalOpen: !this.state.isInternationalImportTaxModalOpen,
    });
  };

  render() {
    const {
      to,
      cash,
      amountDescription,
      checkoutAmountDescription,
      amountWithoutDiscount,
      totalDelivery,
      icms,
      importValue,
      zipcode,
      products,
      promocode,
      discounts,
    } = this.props;

    const {
      isInternationalTaxICMSModalOpen,
      isInternationalImportTaxModalOpen,
      isCouponModalOpen,
    } = this.state;
    const warrantySeller = getChannelConfig('warranty_seller');

    function shipmentCostText() {
      const isZipcodeFilled = Boolean(zipcode);
      return (
        (isZipcodeFilled &&
          ((totalDelivery && totalDelivery !== '0' && toBRL(totalDelivery)) || (
            <span className="BasketPriceBox-prices-free"> Grátis </span>
          ))) || <span>&nbsp;&nbsp;</span>
      );
    }

    function totalServicesCount() {
      return products.reduce((total, product) => {
        return total + (product.services ? product.services.length : 0);
      }, 0);
    }

    function servicesCost() {
      const totalServices = products
        .flatMap((product) => product.services || [])
        .reduce((total, service) => total + parseFloat(service.price), 0);
      return toBRL(totalServices);
    }

    function productPlural() {
      const onlyProductsCount = products.reduce((total, product) => {
        return total + product.quantity;
      }, 0);
      return onlyProductsCount > 1
        ? `Produtos (${onlyProductsCount} itens)`
        : `Produto (${onlyProductsCount} item)`;
    }

    function servicesPlural() {
      const totalServices = totalServicesCount();
      return totalServices > 1
        ? `Serviços (${totalServices} itens)`
        : `Serviço (${totalServices} item)`;
    }

    function shouldRenderInfo() {
      if (products.length === 1 && products[0].store === warrantySeller) {
        return false;
      }

      return true;
    }

    return (
      <div className="BasketPriceBox-prices-value">
        <InternationalTaxICMSModal
          isOpen={isInternationalTaxICMSModalOpen}
          toggle={this.handleInternationalTaxICMSModalToggle}
        />
        <InternationalImportTaxModal
          isOpen={isInternationalImportTaxModalOpen}
          toggle={this.handleInternationalImportTaxModalToggle}
        />
        <div className="BasketPriceBox-price">
          {isCouponJourneyEnable() && (
            <span className="BasketContinueFloating-line-coupon">
              <div className="w-full">
                <PromoCodeTag
                  type="big"
                  promo={{
                    message: getCouponInBasket(promocode, discounts)
                      ? 'Cupom aplicado'
                      : 'Tem um código de cupom?',
                  }}
                  button={{
                    onClick: this.openCouponModal,
                    label: getCouponInBasket(promocode, discounts)
                      ? 'Ver cupons'
                      : 'Inserir',
                  }}
                />
                <PromoCodeModal
                  open={isCouponModalOpen}
                  onClose={this.closeCouponModal}
                />
              </div>
            </span>
          )}

          <span className="BasketContinueFloating-line">
            <span className="BasketPriceBox-prices-titleTotal"> Frete total </span>
            <span className="BasketPriceBox-prices-delivery">{shipmentCostText()}</span>
          </span>

          {!!promocode && discounts?.promocode && (
            <span className="BasketContinueFloating-line">
              <span className="BasketPriceBox-prices-titleTotal"> Cupom </span>
              <span className="BasketPriceBox-prices-coupon BasketPriceBox-prices-free"> - {toBRLwithZero(discounts?.promocode)} </span>
            </span>
          )}

          {shouldRenderInfo() && (
            <span className="BasketContinueFloating-line">
              <span className="BasketPriceBox-prices-titleProducts">
                {' '}
                {productPlural()}
              </span>
              <span className="BasketPriceBox-prices-withoutDiscount">
                {toBRL(amountWithoutDiscount)}
              </span>
            </span>
          )}

          {totalServicesCount() > 0 && shouldRenderInfo() && (
            <span className="BasketContinueFloating-line">
              <span className="BasketPriceBox-prices-titleProducts">
                {' '}
                {servicesPlural()}
              </span>
              <span className="BasketPriceBox-prices-withoutDiscount">
                {servicesCost()}
              </span>
            </span>
          )}

          {importValue && (
            <span className="BasketContinueFloating-line">
              <span className="BasketPriceBox-prices-titleTotal">
                <span
                  className="ti ti-help-outline cursor-pointer"
                  onClick={this.handleInternationalImportTaxModalToggle}
                />{' '}
                Imposto de Importação{' '}
              </span>
              <span className="BasketPriceBox-prices-delivery">{toBRL(importValue)}</span>
            </span>
          )}

          {icms && (
            <span className="BasketContinueFloating-line">
              <span className="BasketPriceBox-prices-titleTotal">
                <span
                  className="ti ti-help-outline cursor-pointer"
                  onClick={this.handleInternationalTaxICMSModalToggle}
                />{' '}
                ICMS{' '}
              </span>
              <span className="BasketPriceBox-prices-delivery">{toBRL(icms)}</span>
            </span>
          )}

          <span className="BasketContinueFloating-line">
            <span className="BasketPriceBox-prices-titleBold"> Total: </span>

            <div className="BasketPriceBox-prices-cash-to">
              <span className="BasketPriceBox-prices-cash">
                {toBRL(cash)} {checkoutAmountDescription || pixDescription}
              </span>
              <span className="BasketPriceBox-prices-to">
                ou {toBRL(to)} {amountDescription || cardDescription}
              </span>
              {(!toBRL(to) && !toBRL(cash) && 'R$ 0,00') || null}
            </div>
          </span>
        </div>
      </div>
    );
  }
}

export default withModal(BasketPrices);
