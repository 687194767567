import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import DialogBottomSheet from 'components/DialogBottomSheet';
import { LoadingWithBackground } from 'components/shared/Loading/Loading';
import PromoCodeInput from 'components/HighlightPromoCode/PromoCodeTag/PromoCodeInput';
import PromoCodeContainer from 'components/HighlightPromoCode/PromoCodeContainer';
import PromoCodeTag from 'components/HighlightPromoCode/PromoCodeTag/PromoCodeTag';
import * as basketActions from 'reducers/basket';
import * as promocodeActions from 'reducers/promocode';
import { detectMobile } from 'utils/responsive';
import {
  couponsAvailableOnProducts,
  couponTitle,
  formatDiscount,
  getCouponInBasket,
} from 'utils/couponHelpers';
import BasketResume from './BasketResume';

const mapStateToProps = ({ basket, promocode }) => ({
  totals: basket.basket.totals,
  discounts: basket.basket.discounts,
  promocode: basket.basket.promocode,
  products: basket.basket.products,
  promocodeInput: promocode.promocodeInput,
  promocodeError: promocode.errorMessage,
  promocodeLoading: promocode.isLoading,
});

const mapDispatchToProps = {
  fetchPromocode: promocodeActions.fetchPromocode,
  removePromocode: promocodeActions.removePromocode,
  changePromocodeInput: promocodeActions.changePromocodeInput,
  promocodeEventCustom: promocodeActions.promocodeEventCustom,
  fetchBasket: basketActions.fetchBasket,
};

@connect(mapStateToProps, mapDispatchToProps)
class PromoCodeModal extends Component {
  static propTypes = {
    totals: PropTypes.shape({
      to: PropTypes.string.isRequired,
      cash: PropTypes.string,
      amountDescription: PropTypes.string,
      checkoutAmountDescription: PropTypes.string,
    }).isRequired,
    discounts: PropTypes.shape({
      promocode: PropTypes.string,
    }),
    promocode: PropTypes.string,
    products: PropTypes.array,
    promocodeInput: PropTypes.string.isRequired,
    promocodeError: PropTypes.string,
    promocodeLoading: PropTypes.bool.isRequired,
    fetchPromocode: PropTypes.func.isRequired,
    changePromocodeInput: PropTypes.func.isRequired,
    fetchBasket: PropTypes.func.isRequired,
    promocodeEventCustom: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
  };

  state = { couponInput: '', availableCoupons: [] };

  componentDidMount() {
    const { products } = this.props;
    const { availableCoupons } = this.state;

    if (availableCoupons.length === 0) {
      this.setState({ availableCoupons: couponsAvailableOnProducts(products) });
    }
  }

  componentDidUpdate(prevProps) {
    const { promocode } = this.props;

    if (prevProps !== this.props && promocode) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ couponInput: promocode });
    }
  }

  handleApplyCoupon = (couponValue, index = null) => {
    const { changePromocodeInput, fetchPromocode, promocodeEventCustom } = this.props;
    const content_id =
      typeof index === 'number'
        ? `lista-de-cupons:${index + 1}:${couponValue.toLowerCase()}`
        : `buscar-cupom:${couponValue.toLowerCase()}`;
    promocodeEventCustom({
      content_id,
    });
    changePromocodeInput(couponValue);
    fetchPromocode(couponValue);
  };

  handleRemoveCoupon = async () => {
    const { removePromocode, promocodeEventCustom, promocode, discounts } = this.props;

    await removePromocode();
    promocodeEventCustom({
      content_id: 'remover',
    });

    if (getCouponInBasket(promocode, discounts) === '') {
      this.setState({ couponInput: '' });
    }
  };

  handleChangeCoupon = (event) => this.setState({ couponInput: event.target.value });

  getErrorMessage = (couponName) => {
    const { promocodeInput, promocodeError } = this.props;

    if (promocodeInput.toUpperCase() === couponName.toUpperCase() && promocodeError) {
      return promocodeError;
    }

    return '';
  };

  render() {
    const { totals, discounts, promocode, promocodeLoading, open, onClose } = this.props;
    const { couponInput, availableCoupons } = this.state;

    return (
      <DialogBottomSheet
        open={open}
        onClose={onClose}
        title={
          <div>
            <span className="gap-sm text-on-surface-3 font-title-default-highlight">
              Quer inserir um cupom?
            </span>
            <p className="pt-md pb-xsm text-on-surface-3 font-body-default md:pt-xsm">
              Informe o código e clique em Aplicar.
            </p>
          </div>
        }
        footerContent={
          detectMobile() && (
            <BasketResume
              totals={totals}
              discounts={discounts}
              promocode={promocode}
              onClick={onClose}
            />
          )
        }
        footerShadow
        headerClassName="!px-md !pt-lg !pb-sm"
        containerClassName="!max-h-[86%] md:!max-h-[70%] md:max-w-[500px] md:min-h-[220px]">
        <div className="py-xsm gap-xsm md:py-[0]">
          <label className="font-body-small gap-2xsm text-on-surface-4">
            Somente 1 cupom por pedido
          </label>
          <PromoCodeInput
            onApplyCoupon={this.handleApplyCoupon}
            onRemoveCoupon={this.handleRemoveCoupon}
            onChangeCoupon={this.handleChangeCoupon}
            getErrorMessage={this.getErrorMessage}
            getCouponInBasket={() => getCouponInBasket(promocode, discounts)}
            couponInput={couponInput}
          />
        </div>

        {availableCoupons.length > 0 && (
          <p className="pb-md pt-sm text-on-surface-3 font-body-default">
            Ou escolha um dos cupons disponíveis:
          </p>
        )}
        <div className="gap-md">
          {availableCoupons.map((availableCoupon, index) => (
            <PromoCodeContainer
              key={availableCoupon.code}
              title={couponTitle(availableCoupon)}
              success={promocode === availableCoupon.code ? 'Aplicado' : ''}>
              <PromoCodeTag
                listItem
                type="big"
                promo={{
                  code: availableCoupon.code,
                  message: formatDiscount(
                    availableCoupon.discount_value,
                    availableCoupon.discount_type,
                    true
                  ),
                }}
                success={promocode === availableCoupon.code ? 'Aplicado' : ''}
                error={this.getErrorMessage(availableCoupon.code)}
                button={{
                  onClick: () => this.handleApplyCoupon(availableCoupon.code, index),
                  label: 'Aplicar',
                }}
                onRemove={this.handleRemoveCoupon}
              />
            </PromoCodeContainer>
          ))}

          {promocodeLoading && <LoadingWithBackground />}
        </div>
      </DialogBottomSheet>
    );
  }
}

export default PromoCodeModal;
